import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import chroma from 'chroma-js'
import { Section, Grid } from 'inno-components'
import Pie from '../../icons/Pie'

import Config from '../../Config'

const styles = StyleSheet.create({
  header: {
    textAlign: 'center'
  },
  test: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '50%',
    backgroundImage: `linear-gradient(${chroma(Config.colors.thirdary)
      .brighten(2)
      .css()}, ${chroma(Config.colors.thirdary)
      .brighten(2)
      .css()})`,
    transform: 'skewY(-6deg)',
    transformOrigin: 'top left'
  },
  pie: {
    width: '100%',
    height: 'auto'
  }
})

class Efficacite extends Component {
  state = { activeIndex: 0 }

  componentDidMount() {}

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index
    })
  }
  onClickLink = route => {
    /*
    const linkComposition = Routes.getLink(
      'raypath',
      `${Routes.index.children.MethodeRaypath.children[route].path}`,
      `${Routes.index.children.MethodeRaypath.children[route].urls.fr}`,
      {}
    )
    Router.push(linkComposition.href, linkComposition.as)
    */
  }
  render() {
    return (
      <div>
        <Section bgColor="#fff">
          <Grid centered stackable>
            <Grid.Row>
              <Grid.Column width={6}>
                <Pie
                  onClickLink={this.onClickLink}
                  className={css(styles.pie)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Section>
      </div>
    )
  }
}

export default Efficacite
