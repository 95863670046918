import React from 'react'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import Index from '../../components/methode-raypath/index'

const CompositionPage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentMethodeRaypath">
    <Index />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('methode-raypath')
)

export default echance(CompositionPage)
